import { useEffect } from "react";
import { CompatRouter, Route, Routes } from "react-router-dom-v5-compat";

import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";

// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import useTheme from "./hooks/useTheme";

import createTheme from "./theme";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-pdfviewer/styles/material.css';

import {
	HomePage,
	NoWorkPage,
	Logout,
	WhoAmI,
	AboutMeSurvey,
	WorkPlaceBigFiveSurvey,
	AssessmentSurvey,
	AssessmentRaters,
} from "./pages";
import { Error } from "./pages/Error";
import ProfilePage from "./pages/ProfilePage";
import ReportViewer from "./pages/ReportViewer";
import { registerLicense } from '@syncfusion/ej2-base';

import { ProtectedRoute } from "./components/shared/protected-route";

import { useEnv } from "./context/env.context";

import { TranslationProvider } from "./context/TranslationProvider";

import "./App.scss";
import { AssessmentsListPage } from "./pages/contentManagement/AssessmentsListPage";
import { AssessmentDetailPage } from "./pages/contentManagement/AssessmentDetailPage";
import { FormItemListPage } from "./pages/contentManagement/FormItemListPage";
import { firebaseConfig_dev, firebaseConfig_prod, firebaseConfig_qa, firebaseConfig_uat } from "./context/firebase.context";


export const App: React.FC = () => {
	const { error, isAuthenticated, getIdTokenClaims } = useAuth0();

	const { theme } = useTheme();
	const { domain } = useEnv();

	registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ed3RcRGNeV0dxWEE="
    );


	useEffect(() => {
		if (isAuthenticated) {
			getIdTokenClaims().then((claims) => {
				sessionStorage.setItem("token", claims?.__raw as string);
			});
		}
		// eslint-disable-next-line
	}, [isAuthenticated]);

	let firebaseApp: FirebaseApp;
	let analytics: Analytics;

	if (domain === "auth.ccl.org") { 
		firebaseApp = initializeApp(firebaseConfig_prod);
		analytics = getAnalytics(firebaseApp);
	} else if (domain ===  "auth-uat.ccl.org") {
		firebaseApp = initializeApp(firebaseConfig_uat);
		analytics = getAnalytics(firebaseApp);
	} else if (domain ===  "auth-qa.ccl.org") {
		firebaseApp = initializeApp(firebaseConfig_qa);
		analytics = getAnalytics(firebaseApp);
	} else if (domain ===  "auth-dev.ccl.org") {
		firebaseApp = initializeApp(firebaseConfig_dev);
		analytics = getAnalytics(firebaseApp);
	}

	if (error !== undefined) {
		console.log(error.message);
		return (
			<div>
				<Error />
			</div>
		);
	}

	return (
		<div className="App" data-testid="main-app-div">
			<ThemeProvider theme={createTheme(theme)}>
				<TranslationProvider>
					<CompatRouter>
						<Routes>
							<Route>
								{/* <VerifyAccess /> */}
								<Route path="/logout" element={<Logout />} />
								<Route
									path="/who-am-i"
									element={<ProtectedRoute component={WhoAmI} />}
								/>
								<Route
									path="/aboutme"
									element={<ProtectedRoute component={AboutMeSurvey} />}
								/>
								<Route
									path="/raters"
									element={<ProtectedRoute component={AssessmentRaters} />}
								/>
								<Route
									path="/survey"
									element={<ProtectedRoute component={AssessmentSurvey} />}
								/>
								<Route
									path="/report"
									element={<ProtectedRoute component={ReportViewer} />}
								/>
								<Route
									path="/wb5p"
									element={
										<ProtectedRoute component={WorkPlaceBigFiveSurvey} />
									}
								/>
								<Route
									path="/profile"
									element={<ProtectedRoute component={ProfilePage} />}
								/>
								<Route
									path="/no-work"
									element={<ProtectedRoute component={NoWorkPage} />}
								/>
								<Route
									path="/"
									element={<ProtectedRoute component={HomePage} />}
								/>
								<Route path="/assessment-management"
									element={<ProtectedRoute component={AssessmentsListPage} />}
								/>
								<Route path="/assessment-management/:id" 
									element={<ProtectedRoute component={AssessmentDetailPage}  />}
								/>
								<Route path="/assessment-management/:id/:formMasterKey" 
									element={<ProtectedRoute component={FormItemListPage} />}
								/>
							</Route>
						</Routes>
					</CompatRouter>
				</TranslationProvider>
			</ThemeProvider>
		</div>
	);
};
