import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom-v5-compat";

import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "@mui/material";
import { NavigationModes, SurveySection } from "../../@types/surveys.d";

import {
	fetchSurvey,
	setCurrentSection,
	setInstrumentId,
	setParticipantId,
	setSurveyReopened,
} from "../../store/User/UserSlice";
import {
	setContinueAtNextUnanswered,
	setCurrentNavigationMode,
	setMaximumPrevious,
	setReturnUrl,
	setShouldRenderSeqNum,
	setShowSectionSubheading,
} from "../../store/Navigation/NavSlice";
import { Survey } from "../../components/surveys/Survey";

import { Layout } from "../../components/chrome/Layout";

import { RootState } from "../../store";

import "./Surveys.scss";
import usePageTitle from "../../hooks/pageTitle";

export const WorkPlaceBigFiveSurvey: React.FC = () => {
	const dispatch = useDispatch();
	const { user } = useAuth0();

	const [search] = useSearchParams();

	const { selectedSurveyType, selectedSurveyLanguage } = useSelector(
		(state: RootState) => state.user
	);

	const asyncLoadingTasks = async () => {
		// On init page load, go get the user's About Me survey
		let temp = search.get("id");
		let insId = temp ? parseInt(temp) : 2947; // Default is Workplace Big 5 - 5.0

		temp = search.get("pid");
		let pid = temp ? parseInt(temp) : 0; // Default is we don't know.

		temp = search.get("retUrl");
		let retUrl = temp ?? ""; // Default is none.

		let requestData = {
			user: user,
			instrumentId: insId,
			participantId: pid,
			languageId: selectedSurveyLanguage?.languageId,
			surveyTypeId: selectedSurveyType,
		};
		dispatch(fetchSurvey(requestData));
		dispatch(setInstrumentId(insId));
		dispatch(setParticipantId(pid));
		dispatch(setReturnUrl(retUrl));
		dispatch(setSurveyReopened(false));
		// setting this to true causes the question to repeat sometimes
		dispatch(setContinueAtNextUnanswered(false));
		dispatch(setShowSectionSubheading(false));
		dispatch(setShouldRenderSeqNum(true));

		// WPB5 settings
		dispatch(setCurrentNavigationMode(NavigationModes.ByQuestion));
		dispatch(setMaximumPrevious(1));
		dispatch(setCurrentSection({} as SurveySection));
	};

	usePageTitle("WorkPlace Big Five Profile 5.0");

	useEffect(() => {
		asyncLoadingTasks();
	}, []);

	useEffect(() => {

	},[selectedSurveyLanguage])

	return (
		<Layout>
			<Container id="wpb5_survey">
				<Survey centerQuestions={true} />
			</Container>
		</Layout>
	);
};
