import { Fragment, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { InstrumentStatus } from "../../@types/surveys.d";
import { DisplayInstramentLink } from "./DisplayProgramInstrumentStatus";
import aboutMeImage from "../../assets/images/about_me_banner.png";
import { FormattedPhrase } from "../../context/FormattedPhrase";

import "../HomePage.scss";

type ComponentProps = {
	instrumentStatus: InstrumentStatus;
};

export const DisplayAboutMeStatus: React.FC<ComponentProps> = ({
	instrumentStatus,
}) => {
	useEffect(() => {}, [instrumentStatus]);

	return (
		<Fragment>
			<Card
				sx={{
					mb: 8,
				}}
			>
				<CardMedia
					component="img"
					image={aboutMeImage}
					alt="Surveys for Me Card Image"
					sx={{
					width: '100%',
					height: '60%',
					objectFit: 'cover',
					}}
				/>
				<CardContent>
					<Grid container spacing={0} rowSpacing={0}>
						<Grid item xs={12} sx={{ mt: 5 }}>
							<Typography variant="title" fontWeight={700}>
								<FormattedPhrase id="participant.aboutMe.assessmentName" />
							</Typography>
							<Typography variant="body1" mt={3}>
								<FormattedPhrase id="surveys.aboutMe.description" />
							</Typography>
						</Grid>
						<Grid item sx={{ mt: 5, marginLeft: "auto" }}>
							<DisplayInstramentLink
								instrumentId={instrumentStatus.instrumentId}
								participantId={instrumentStatus.participantId}
								instrumentStatus={instrumentStatus}
								statusId={instrumentStatus?.selfStatus?.statusId as number}
								category={"About Me"}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Fragment>
	);
};
