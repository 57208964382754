import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Fade from '@mui/material/Fade';
import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
	useTheme,
} from "@mui/material";

import {
	clearItemResponse,
	saveItemResponse,
} from "../../../services/apiService";
import { setActiveAnswers, setLoading } from "../../../store/User/UserSlice";
import { RootState } from "../../../store";
import { DisplayQuestionGroupScale } from "./DisplayQuestionGroupScale";
import {
	ConditionalValidator,
	Question,
	SurveyAnswer,
	ReviewQuestion,
} from "../../../@types/surveys.d";

import { SingleSelectComponent } from "./questionTypes/SingleSelectComponent";
import { MultiSelectComponent } from "./questionTypes/MultiSelectComponent";
import { SurveyAOrBQuestionsComponent } from "./questionTypes/SurveyAOrBQuestionsComponent";
import { SurveyAAndBQuestionsComponent } from "./questionTypes/SurveyAAndBQuestionsComponent";
import { RadioComponent } from "./questionTypes/RadioComponent";
import { TextComponent } from "./questionTypes/TextComponent";
import { MultiEditsComponent } from "./questionTypes/MultiEditsComponent";
import {
	setCheckedCount,
	setDefaultCount,
	setGoBack,
	setQuestionNumber,
	setReviewUnanswered,
	setSectionId,
} from "../../../store/Navigation/NavSlice";
import { getAnswerCount, getRequiredAnswersCount } from "../store/SurveyState";
import { useTranslationContext } from "../../../context/TranslationContext";
import { AlertModal } from "../../shared/alertModal";

export type QuestionProps = {
	question: Question;
	questionGroup: any;
	centerQuestions: boolean;
	hideNumber?: boolean;

	handleResponse?: (
		itemId: number,
		sequenceNum: number,
		response: string,
		append: boolean
	) => void;
};

export const DisplayQuestion: React.FC<QuestionProps> = ({
	question,
	questionGroup,
	centerQuestions,
	hideNumber,
}) => {
	const dispatch = useDispatch();
	const { user } = useAuth0();

	const {
		activeSurvey,
		activeAnswers,
		currentSection,
		instrumentId,
		questionList,
		conditionalQuestions
	} = useSelector((state: RootState) => state.user);

	const { checkedCount, defaultCount, reviewUnanswered, questionNumber, sectionId } =
		useSelector((state: RootState) => state.navigation);

	const [selectedAnswer, setSelectedAnswer] = useState<string>(
		question?.answer ?? ""
	);
	const [selectedAnswerArray, setSelectedAnswerArray] = useState<Array<string>>(
		[]
	);
	const [disableCheck, setDisableCheck] = useState(false);
	const [requiredCount, setRequiredCount] = useState<number>(0);
	const { getFormattedPhrase } = useTranslationContext();
	const theme = useTheme();
	const [isOnline, setOnline] = useState(()=> {
        return navigator.onLine
    })
	const [alertOpen, setAlertOpen] = useState(false);

	let component = question?.component ?? currentSection?.component ?? "";

	const getSelectedAnswer = (formItemId: number) => {
		let givenAnswers = activeAnswers.filter((a) => a.formItemId === formItemId);
		if (givenAnswers && givenAnswers.length > 0) {
			return givenAnswers[givenAnswers.length - 1].answer;
		}
		return "";
	};

	const getSelectedAnswerArray = (formItemId: number) => {
		let givenAnswers = activeAnswers.filter((a) => a.formItemId === formItemId);
		if (givenAnswers?.length) {
			if (givenAnswers[givenAnswers.length - 1].answer) {
				var values = givenAnswers[givenAnswers.length - 1].answer.split("|");
				return values;
			}
		}
		return [];
	};

	useEffect(() => {
		if (
			currentSection &&
			currentSection?.component === "SurveyCompetenciesQuestionsComponent"
		) {
			let currentSectionRule: any = currentSection;
			if (currentSectionRule?.validationRule?.conditions?.all) {
				if (
					currentSectionRule.component ===
					"SurveyCompetenciesQuestionsComponent"
				) {
					let numberAnswered = getAnswerCount(
						currentSection,
						questionList,
						instrumentId,
						activeAnswers
					);
					dispatch(setCheckedCount(numberAnswered));
					let requiredAnswers = getRequiredAnswersCount(
						currentSection?.validationRule
					);
					setRequiredCount(requiredAnswers);
					setDisableCheck(true);
					let questions = currentSectionRule?.questionGroups.map(
						(a) => a.questions
					);
					let arr: any = [];
					questions.map((b: any) => {
						b.filter((x) => {
							if (x.answer !== null && x.answer === "1") {
								arr.push(x);
							}
						});
					});
					// allow only n values
					if (arr.length >= requiredAnswers) {
						// if the answers were already saved, splice the last n records and mark as unchecked
						let arrToRemove = arr.splice(requiredAnswers, arr.length);
						for (var i = 0; i < arrToRemove.length; i++) {
							handleResponse &&
								handleResponse(
									arrToRemove[i].formItemId,
									arrToRemove[i].formItemSequenceNumber,
									selectedAnswer === "1" ? " " : "1", //ref 77901 for why we don't pass 0 and have to pass a whitespace
									false
								);
						}
					} else {
						setDisableCheck(false);
					}
				}
			}
		}
	}, [currentSection]);

	useEffect(() => {
		if (checkedCount < requiredCount) {
			setDisableCheck(false);
		} else {
			setDisableCheck(true);
		}
	}, [disableCheck, checkedCount]);

	useEffect(() => {},[defaultCount]);

	const handleChange = (e) => {
		e.preventDefault();
		dispatch(setGoBack(true));
		if (e.target.checked) {
			handleResponse &&
				handleResponse(
					question.formItemId,
					question.formItemSequenceNumber,
					"1",
					false
				).then(() => {
					if (!defaultCount) dispatch(setCheckedCount(checkedCount + 1))});
		} else {
			handleResponse &&
				handleResponse(
					question.formItemId,
					question.formItemSequenceNumber,
					" ", //ref 77901 for why we don't pass 0 and have to pass a whitespace
					false
				).then(() => { if (!defaultCount) dispatch(setCheckedCount(checkedCount - 1))});
		}
	};

	useEffect(()=>{
        window.addEventListener('online', ()=> setOnline(true))
        window.addEventListener('offline', ()=> setOnline(false) )

        return ()=>{
            window.removeEventListener('online', ()=> setOnline(true))
            window.removeEventListener('offline', ()=> setOnline(false) )
        }
    })

	useEffect(() => {
		if (question) {
			// buildQuestionList(
			// 	activeSurvey?.surveyDocument.survey.surveySections,
			// 	activeSurvey.surveyAnswers
			// );
			var answer = getSelectedAnswer(question.formItemId);
			setSelectedAnswer(answer);

			var answerArray = getSelectedAnswerArray(question.formItemId);
			setSelectedAnswerArray(answerArray);
			let givenAnswers = activeAnswers.filter(
				(a) => a.formItemId === question.formItemId
			);
			if (givenAnswers && givenAnswers.length > 0) {
				setSelectedAnswer(givenAnswers[givenAnswers.length - 1].answer);

				// Now the array versions.
				if (givenAnswers[givenAnswers.length - 1].answer) {
					var values = givenAnswers[givenAnswers.length - 1].answer.split("|");
					setSelectedAnswerArray(values);
				} else {
					setSelectedAnswerArray([]);
				}
			} else {
				setSelectedAnswer("");
				setSelectedAnswerArray([]);
			}
			let allAnswers: any = [];
			conditionalQuestions?.map(x => {
				if (x.question?.conditionalValidators?.length) {
					x.question?.conditionalValidators?.map((a => {
						// Real survey doesn't have formItemId set in conditional validation
						if (instrumentId === 2948) {
							// get the correct formItemId
							var itemId: any = questionList.filter(z => z.formItemSequenceNumber === a.formItemSequenceNumber);
							if (itemId) {
								allAnswers = a.answers;
								// get the parent question
								let parentQuestion = itemId.map(y => y.question);
								let parentAnswer = getSelectedAnswer(parentQuestion[0].formItemId);
								let currentAnswer = getSelectedAnswer(x.formItemId);
								if (parentQuestion.length && !allAnswers.includes(parentAnswer)) {
									if (currentAnswer && currentAnswer !== "") {
										dispatch(setLoading(true));
										handleResponse && handleResponse(
											x.formItemId,
											x.formItemSequenceNumber,
											"",
											false
										);
									}
								}
								else {
									return;
								}
							}
						} else {
							let parentQuestion = getSelectedAnswer && getSelectedAnswer(a.formItemId);
							allAnswers = parentQuestion.split("|");
							let currentAnswer = getSelectedAnswer(x.formItemId);
							if (!allAnswers.includes(a.answers[0])) {
								if (currentAnswer && currentAnswer !== "") {
									dispatch(setLoading(true));
									handleResponse && handleResponse(
										x.formItemId,
										x.formItemSequenceNumber,
										"",
										false
									);
								}
							}
							else {
								return;
							}
						}
					}));
				}
			});
		}
	}, [question, activeAnswers, conditionalQuestions]);

	useEffect(() => {
		if (
			reviewUnanswered?.sectionId !== "" &&
			reviewUnanswered?.formItemId > 0
		) {
			const element = document.querySelector(
				`#question_${reviewUnanswered?.formItemId}`
			);
			if (element) {
				// if the welcome component has a question, do not scroll to the question
				let componentSection = activeSurvey?.surveyDocument?.survey?.surveySections.find(a => a.sectionId === reviewUnanswered?.sectionId);
				if (componentSection) {
					if (componentSection.component === "SurveyWelcomeComponent") {
						return;
					}
				}
				//necessary to avoid incomplete scrolling
				setTimeout(() => {
					element.scrollIntoView({ behavior: "smooth", block: "center" });	
				}, 0);
			}
			dispatch(setReviewUnanswered({} as ReviewQuestion));
		}
		if (questionNumber > 0 && sectionId !== "") {
			const element = document.querySelector(`#question_${questionNumber}`);
			if (element) {
				setTimeout(() => {
					element.scrollIntoView({ behavior: "smooth", block: "center" });
				}, 0);
			}
			dispatch(setSectionId(""));
		}
	}, [reviewUnanswered, questionNumber]);

	useEffect(() => {
		dispatch(setQuestionNumber(0));
	}, [reviewUnanswered, isOnline]);

	const aboutMeConditionalBlock = async (surveyDocId: string, itemId: number, resp: string) => {
		if (itemId === 1) {
			if (resp === '0') {
				// console.log('Not US Resident');
				await handleResponse(15, 15, "", false);
				await handleResponse(18, 18, "", false);
			} else if (resp === '1') {
				// console.log('US Resident');
				await handleResponse(2, 2, "", false);
				await handleResponse(12, 12, "", false);
			}
		}
	}

	const handleResponse = async (
		itemId: number,
		sequenceNum: number,
		response: string,
		append: boolean
	) => {
		try {
			dispatch(setLoading(true));

			let respToSave = "";
			if (append) {
				let index = selectedAnswerArray.indexOf(response);
				let updated: Array<string> = [];
				if (index >= 0) {
					// Removing
					updated = selectedAnswerArray.filter((x) => x !== response);
				} else {
					// Adding
					updated = [...selectedAnswerArray, response];
				}
				respToSave = updated.join("|");
				setSelectedAnswerArray(updated);
			} else {
				respToSave = response;
				setSelectedAnswer(respToSave);
			}

			if (user && instrumentId !== 0) {
				if (instrumentId === 2946) {
					await aboutMeConditionalBlock(activeSurvey.surveyDocument.id, itemId, respToSave);
				}

				let saveResponse: any = {};
				let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];
				if (respToSave === "") {
					await clearItemResponse(
						activeSurvey.surveyDocument.id,
						instrumentId,
						itemId,
						sequenceNum
					).then((res) => {
						let newAnswer = {
							surveyDocumentId: activeSurvey.surveyDocument.id,
							formItemId: res.answers[0].formItemId,
							formItemSequenceNumber: sequenceNum,
							optional: res.answers[0].optional,
							answer: res.answers[0].answer,
						};
						let updated = [...activeAnswers, newAnswer];
						dispatch(setActiveAnswers(JSON.stringify(updated)));
						dispatch(setDefaultCount(false));
					})
				} else {
					await saveItemResponse(
						activeSurvey.surveyDocument.id,
						instrumentId,
						itemId,
						sequenceNum,
						respToSave
					).then((res) => {
						let newAnswer = {
							surveyDocumentId: activeSurvey.surveyDocument.id,
							formItemId: res.answers[0].formItemId,
							formItemSequenceNumber: sequenceNum,
							optional: res.answers[0].optional,
							answer: res.answers[0].answer,
						};
						let updated = [...activeAnswers, newAnswer];
						dispatch(setActiveAnswers(JSON.stringify(updated)));
						dispatch(setDefaultCount(false));
					})
				}
			}
		} catch (exception) {
			// in case the saving fails for some reason, we'll just show the last response for that question
			console.error(exception);
			let notUpdated = selectedAnswerArray.filter((x) => x !== response);
			setSelectedAnswerArray(notUpdated);
			// get the last response
			var answer = getSelectedAnswer(question.formItemId);
			setSelectedAnswer(answer);
			// show the same checked count as previous as the response was not changed
			dispatch(setDefaultCount(true));
			setAlertOpen(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const renderQuestionText = () => {
		let component = question.component ?? currentSection.component ?? "";
		switch (component) {
			case "SurveyWelcomeComponent":
				//This type does not have a 'question',
				//the answer option is just a consent-agreement
				return <></>;
			case "SurveyAAndBQuestionsComponent":
			case "SurveyAOrBQuestionsComponent":
				return (
					<Grid item xs={1} sx={{ py: 5 }} className={"question-text"}>
						<Typography variant="h6" fontWeight={700}>
							{question.number}.
						</Typography>
					</Grid>
				);

			case "SurveyCompetenciesQuestionsComponent":
				return (
					<Grid item xs={11} sx={{ p: 2 }} className="answer-input">
						<FormControlLabel
							className="checkboxClass"
							control={
								<Checkbox
									name={`checkbox-group-${question.formItemId}`}
									checked={selectedAnswer === "1"}
									onChange={(e) => {
										if (isOnline) {
											handleChange(e);
										}
										else {
											setAlertOpen(true);
										}
									}}
									sx={{
										"&.Mui-disabled": {
											color: theme.palette.warning.dark,
										},
									}}
								/>
							}
							disabled={disableCheck && selectedAnswer !== "1"}
							name="checkbox"
							label={
								<Typography style={{fontWeight: "bold"}}>
									{question.text}
								</Typography>
							}
							id={question.formItemId.toString()}
						/>
						<Typography className="checkboxSubText">
							{question.subtext}
						</Typography>
					</Grid>
				);

			default:
				let questionText = question.text;
				return (
					<Grid
						item
						xs={centerQuestions ? 12 : 9}
						className={"question-text" + (centerQuestions ? " center" : "")}
						sx={{ wordBreak: (window.innerWidth < 920 || questionText.length > 150) ? 'break-word' : 'normal' }}
					>
							{!hideNumber && question.number?
								<Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
									<Grid item mr={2}>
										<Typography textAlign={"center"} fontWeight={700}>
											{/* <ReactMarkdown
												rehypePlugins={[rehypeRaw]}
											> */}
												{question.number + "."}
											{/* </ReactMarkdown> */}
										</Typography>
									</Grid>
									<Grid item xs={11} pb={2} pl={centerQuestions ? "4%" : 0}>
										<Typography  fontWeight={700}>
											<ReactMarkdown
												className="question-number"
												rehypePlugins={[rehypeRaw]}
											>
												{questionText}
											</ReactMarkdown>
										</Typography>
									</Grid>
								</Grid>
							:
								<Typography variant="question" fontWeight={700}>
									<ReactMarkdown
										className="question-number"
										rehypePlugins={[rehypeRaw]}
									>
										{questionText}
									</ReactMarkdown>
								</Typography>
							}
							<Typography variant="question" fontWeight={700}>
								{question?.subtext && (
									<ReactMarkdown
										rehypePlugins={[rehypeRaw]}
										className="question-number"
									>
										{question.subtext}
									</ReactMarkdown>
								)}
							</Typography>
					</Grid>
				);
		}
	};

	const checkIfAgeQuestion = (formItemId: number) => {
		if (formItemId === 1026242) {
			return true;
		}
		return false;
	}

	const renderAnswerInput = () => {
		switch (component) {
			case "SingleSelectComponent":
				return (
					<Grid item xs={12} sx={{ p: 2 }} className="answer-input">
						<SingleSelectComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);

			case "SingleOptionComponent":
				return (
					<Grid item xs={12} sx={{ p: 2 }} className="answer-input">
						<RadioComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);

			case "MultiSelectComponent":
			case "MultiOptionsComponent":
				return (
					<Grid item xs={12} sx={{ p: 2 }} className="answer-input">
						<MultiSelectComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);

			case "MultiEditsComponent":
				return (
					<Grid item xs={12} sx={{ p: 2 }} className="answer-input">
						<MultiEditsComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);

			case "SurveyWelcomeComponent":
			//This takes "Yes I agree / understand", and is usually just one option
			case "RadioComponent":
				return (
					<Grid item xs={12} sx={{ p: 2 }} className="answer-input">
						<RadioComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);

			case "TextComponent":
			case "TextInputComponent":
			case "SurveyDirectFeedbackQuestionsComponent":
			case "SurveyDirectFeedbackTabbedQuestionsComponent":
			case "SingleEditComponent":
				return (
					<Grid
						item
						xs={component == "SingleEditComponent" ? 12 : 9}
						sx={{ px: 2, pt: 2 }}
						className="answer-input"
					>
						{
							checkIfAgeQuestion(question.formItemId)
							? 
								<Grid item xs={3} sx={{ p: 2 }} className="answer-input">
									<SingleSelectComponent
										questionGroup={questionGroup}
										question={question}
										centerQuestions={centerQuestions}
										handleResponse={handleResponse}
									/>
								</Grid>
							:
								<TextComponent
									questionGroup={questionGroup}
									question={question}
									centerQuestions={centerQuestions}
									handleResponse={handleResponse}
								/>
						}
					</Grid>
				);

			case "SurveyAAndBQuestionsComponent":
				return (
					<Grid item xs={11} sx={{ p: 2 }} className="answer-input">
						<SurveyAAndBQuestionsComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);

			case "SurveyAOrBQuestionsComponent":
				return (
					<Grid item xs={11} sx={{ mx: -15, py: 2, px: 3 }} className="answer-input">
						<SurveyAOrBQuestionsComponent
							questionGroup={questionGroup}
							question={question}
							centerQuestions={centerQuestions}
							handleResponse={handleResponse}
						/>
					</Grid>
				);
		}
	};

	const renderQuestionScale = () => {
		switch (question.component) {
			case "":
			case null:
				return (
					<Fragment>
						{questionGroup.surveyScale && (
							<DisplayQuestionGroupScale
								question={question}
								questionGroup={questionGroup}
							/>
						)}
					</Fragment>
				);

			default:
				return <Fragment></Fragment>;
		}
	};

	const renderWithConditions = () => {
		let arrMatchFlags: Array<boolean> = [];

		question.conditionalValidators.map((cv: ConditionalValidator) => {
			/* Overview:
			 * If there are any conditional validation rules, we process them to determine if/how to display this question.
			 * For each rule:
			 * 		1. Find the parent question.
			 * 		2. Validate based on the given rules.
			 * 	ex: If parent answer(s) equals a given value, show or hide this question.
			 */

			if (cv.formItemSequenceNumber) {
				// Based on another question, so find that question.
				let foundResponses = activeAnswers.filter(
					(a) => a.formItemSequenceNumber === cv.formItemSequenceNumber
				);
				let matchFound = false;

				if (foundResponses.length === 0) {
					arrMatchFlags = [];
					arrMatchFlags.push(matchFound);
				}
				foundResponses?.map((resp: SurveyAnswer) => {
					let lastResponse = foundResponses[foundResponses?.length - 1];
					if (lastResponse) {
						var values = lastResponse.answer.split("|");

						matchFound = false;
						values.map((val: string) => {
							if (cv.answers) {
								cv.answers.map((a: string) => {
									if (a === val) {
										matchFound = true;
									}
								});
							}
						});

						arrMatchFlags.push(matchFound && cv.visible);
					}
				});
			}
		});

		let shouldRender = arrMatchFlags.length > 0 && arrMatchFlags[0];
		arrMatchFlags.map((flag: boolean) => {
			shouldRender = shouldRender && flag;
		});

		return (
			<Fragment>
				{shouldRender && (
					<Fade {...{ timeout: 800 }} in={true}>
						<Box flex={1}>
							{renderQuestionText()}
							{renderAnswerInput()}
							{renderQuestionScale()}
						</Box>
					</Fade>
				)}
			</Fragment>
		);
	};

	return (
		<Fragment>
			<AlertModal text={getFormattedPhrase("error.savingResponse")} onClose={() => setAlertOpen(false)} isOpen={alertOpen} />
			{question && (
				<div id={`question_${question.number}`}>
					<Grid
						aria-describedby={`${currentSection.component}`}
						container
						className={`question ${selectedAnswer !== "" ? "answered" : ""}`}
						id={`question_${question.formItemId}`}
					>
						{question.conditionalValidators ? (
							<Fragment>{renderWithConditions()}</Fragment>
						) : (
							<Fragment>
								{renderQuestionText()}
								{renderAnswerInput()}
								{renderQuestionScale()}
							</Fragment>
						)}
					</Grid>
				</div>
			)}
		</Fragment>
	);
};
