import * as React from "react";
import { Fragment } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Link as RouterLink,
	useLocation,
} from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";

import { useAuth0 } from "@auth0/auth0-react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AppBar,
	Box,
	Button,
	Fab,
	Grid,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Modal,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { fetchProfile, fetchVideos, setActiveSurvey, setApplicationMessage, setUserIdForAnalytics } from "../../store/User/UserSlice";
import { RootState } from "../../store";
import {
	setCurrentNavigationMode,
	setGoBack,
	setMainTabGroup,
	setRaterPageTitle,
	setReturnUrl,
	setSelectedSection,
	setTabs,
} from "../../store/Navigation/NavSlice";
import components from "../../theme/components";

import TabbedNavigation from "./TabbedNavigation";
import TabGroup from "./TabGroup";
import { useTranslationContext } from "../../context/TranslationContext";

import { FormattedPhrase } from "../../context/FormattedPhrase";
import CloseIcon from "@mui/icons-material/Close";

import "./Header.scss";
import { NavigationModes, SurveyResponse } from "../../@types/surveys.d";
import { getApplicationResponse } from "../../services/apiService";
import { UserServices } from "../../services/userService";


const ResponsiveAppBar = () => {
	const { user } = useAuth0();
	const userService = new UserServices();
	const theme = useTheme();
	const [activeFaq, setActiveFaq] = useState(-1);
	const [search] = useSearchParams();

	const { getFormattedPhrase, setChosenLanguage } = useTranslationContext();

	const [FAQModalOpen, setFAQModalOpen] = useState(false);

	const { selectedLanguage, profileResponse } = useSelector(
		(state: RootState) => state.user
	);

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const labels = {
		logOut: getFormattedPhrase("appMenu.logout"),
	};

	const { selectedSection, tabs, mainTabGroup, raterPageTitle, returnUrl } = useSelector(
		(state: RootState) => state.navigation
	);
	const { isAuthenticated, getIdTokenClaims } = useAuth0();
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorElUser);
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	useEffect(() => {
		if (isAuthenticated) {
			getIdTokenClaims().then((claims) => {
				getProfile(claims?.__raw as string);
			});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (profileResponse) {
			let language = profileResponse?.applicationLanguages?.find(
				(l) =>
					l.languageId ===
					profileResponse.individual.preferredApplicationLanguageId
			);
			if (language) {
				setChosenLanguage(language?.cultureName);
			}

			if (profileResponse.landingPage === "/no-work" 
				&& (location.pathname !== "/profile"
				&& !location.pathname.startsWith("/assessment-management"))
			) {
				navigate("/no-work");
			} else if (profileResponse.isParticipant) {
				dispatch(setSelectedSection("Own"));
			} else if (profileResponse.isRater) {
				dispatch(setSelectedSection("Others"));
			}
			// call only when the profile data is retrieved properly
			if (profileResponse?.preferredLanguageId) {
				getApplicationResponse(profileResponse.preferredLanguageId).then((res) => {
					if (res) {
						dispatch(setApplicationMessage(res.applicationMessage));
					}
				}).catch((ex) => {
					console.log("something went wrong");
				});
			}
		}
	}, [profileResponse]);

	const backAction = () => {
		//check if the user is coming from AP1
		const retUrl = search.get("retUrl");
		if (retUrl) {
			//take them back to where the retUrl directs, through the state
			dispatch(setReturnUrl(retUrl));
		}
		else {
			clearTabs();
			navigate("/");
		}
	}

	const clearTabs = () => {
		dispatch(setActiveSurvey({} as SurveyResponse));
		dispatch(setRaterPageTitle(""));
		dispatch(setCurrentNavigationMode(NavigationModes.BySection));
		dispatch(setTabs([]));
		dispatch(setMainTabGroup([]));
		dispatch(setGoBack(false));
		sessionStorage.setItem("redirectUser", "");
		sessionStorage.setItem("redirectOnLogOut", "");
	};

	const redirectLogic = () => {
		if (returnUrl !== "") {
			sessionStorage.setItem("redirectOnLogOut", "true")
		}
	}

	const getProfile = (token: string) => {
		if (user) {

			let imKey =
				user["http://schemas.ccl.org/accounts/claims/ccl-identity/id"];

			let data = {
				user: imKey,
				languageId: selectedLanguage?.languageId,
				token: token
			};
			dispatch(fetchProfile(data));
			if (selectedLanguage) {
				dispatch(fetchVideos(selectedLanguage.cultureName));
			}
			dispatch(setUserIdForAnalytics(user["http://schemas.ccl.org/accounts/claims/account/analytics-id"] ?? "id_unavailable"));
		}
	};

	const getFirstName = () => {
		if (user && profileResponse) {
			let firstName =
				profileResponse?.individual?.firstName ?? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
			return firstName;
		}
	};

	const getInitials = () => {
		if (user && profileResponse) {
			let first =
				profileResponse?.individual?.firstName ?? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"];
			let last =
				profileResponse?.individual?.lastName ?? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"];
			return `${first.substring(0, 1)}${last.substring(0, 1)}`;
		}

		return "Menu";
	};

	const renderFAQ = () => {
		let faqContent = JSON.parse(getFormattedPhrase("dialog.faq.text"));

		return (
			<Typography variant="subtitle1">
				{faqContent.length > 0 ? (
					faqContent.map((faq, index) => {
						return (
							<Accordion
								key={index}
								expanded={activeFaq === index}
								onChange={() => setActiveFaq(activeFaq === index ? -1 : index)}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<div dangerouslySetInnerHTML={{ __html: faq.question }}></div>
								</AccordionSummary>
								<AccordionDetails>
									<div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
								</AccordionDetails>
							</Accordion>
						);
					})
				) : (
					<></>
				)}
			</Typography>
		);
	};

	const getBackButton = (type: number) => {
		let topValue = window.innerWidth < 500 ? "1vh" : 79;
		if(location.pathname !== "/") {
			return (
				<Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<Box position={type === 1 ? "absolute" : "revert"} top={type === 1 ? topValue : "unset"}>
					<Link
						style={{ textDecoration: "none" }}
						to="/"
						component={RouterLink}
						onClick={() => backAction()}
					>
						<Button
							variant="contained"
							className="button-header"
							sx={{
								backgroundColor: "#FFF !important",
								borderRadius: "100px",
								margin: 4,
								borderWidth: window.innerWidth > 1024 ? 0 : 1,
								borderStyle: "solid",
								borderColor: theme.palette.primary.main,
								height: 30
							}}
							onClick={() => clearTabs()}
						>
							<Typography
								variant="subtitle1"
								sx={{
									marginRight: 2,
									marginLeft:
										selectedLanguage?.direction === "rtl" ? 2 : 0,
									color: theme.palette.primary.main,
								}}
							>
								<i className="fa-solid fa-arrow-left" />
							</Typography>
							<Typography sx={{ color: theme.palette.primary.main }}>
								<FormattedPhrase id="dashboard.dashboard" />
							</Typography>
						</Button>
					</Link>
					</Box>
					{
						window.innerWidth <= 1024 && getLogo()
					}
				</Box>
			)
		}
		else return getLogo()
	}

	const getLogo = () => {
		return (
			<Link
				sx={{ display: { xs: "none", md: "flex" }, ml: "0.4%" }}
				to="/"
				onClick={() => backAction()}
				component={RouterLink}
			>
				<img
					src="https://public-assets.ccl.org/images/logos/ccl/2-line/ccl-logo-color.svg"
					className="nav-logo"
					alt="Center for Creative Leadership"
				/>
			</Link>
		)
	}

	return (
		<Fragment>
			<AppBar position="static">
				<Modal open={FAQModalOpen} onClose={() => setFAQModalOpen(false)}>
					<Box sx={components.MuiModal} height={"75%"}>
						<Stack direction="row" alignItems={"center"} mb={5}>
							<Typography variant="h3" m={1} sx={{ flex: 1 }}>
								<FormattedPhrase id="dialog.faq.title" />
							</Typography>
							<CloseIcon
								sx={{ mr: "1%", cursor: "pointer" }}
								onClick={() => setFAQModalOpen(false)}
							/>
						</Stack>
						<Box overflow={"scroll"} height={"85%"}>
							{renderFAQ()}
						</Box>
					</Box>
				</Modal>
				<Toolbar sx={{justifyContent: "space-between", backgroundColor: "#FFFFFF", border: 1, borderColor: "#C3C6CF" }}>
					{
						window.innerWidth <= 1024 ? getBackButton(1) : getLogo()
					}
					<Typography
						sx={{
							display: { xs: "none", md: "flex" },
							fontSize: "2.25rem",
							color: theme.palette.text.primary,
							fontWeight: 500
						}}
						textAlign={"center"}
					>
						<FormattedPhrase id="dashboard.header.title" />
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-haspopup="true"
							aria-controls="menu-appbar"
							color="inherit"
						>
							<Typography
								variant="title"
								sx={{ color: theme.palette.secondary.light }}
							>
								{/* <i className="fa-solid fa-bars" /> */}
							</Typography>
						</IconButton>
					</Box>
					<Link onClick={handleOpenUserMenu} style={{textDecoration: "none", cursor: "pointer"}}>
						<Box
							sx={{
								flexGrow: 0,
								marginRight: 0,
							}}
							flexDirection="column"
						>
							<Stack direction="row">
								<Typography
									sx={{ color: theme.palette.text.primary, marginX: 3, marginY: 2 }}
									variant="subtitle1"
								>
									<FormattedPhrase id="dashboard.header.greeting" />, {getFirstName()}
								</Typography>
								<Tooltip title={getFormattedPhrase("tooltip.accountSettings")}>
									<Fab
										className="menu-button"
										color="primary"
										aria-controls={open ? "account-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										sx={{
											m: 3,
											p: 3,
											width: 50,
											height: 50,
											backgroundColor: theme.palette.success.dark,
											zIndex: 99
										}}
										onClick={handleOpenUserMenu}
									>
										<Typography variant="h4" color={"#ffffff"}>
											{getInitials()}
										</Typography>
									</Fab>
								</Tooltip>
							</Stack>
						</Box>
					</Link>
					<Menu
						anchorEl={anchorElUser}
						id="account-menu"
						open={open}
						onClose={handleCloseUserMenu}
						onClick={handleCloseUserMenu}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								// ml: selectedLanguage.direction === "ltr" ? 6 : -6,
								minWidth: 170,
								minHeight: 140,
								"& .MuiAvatar-root": {
									width: 25,
									height: 25,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "inline",
									position: "absolute",
									top: 0,
									right: 5,
									width: 10,
									height: 10,
									borderTop: 'none',
									bgcolor: "background.paper",
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: "center", vertical: "top" }}
						anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
					>
						<Link
							underline="none"
							to="/profile"
							component={RouterLink}
							sx={{textDecoration: "none", flexDirection: "row", display: "flex","&:hover": {
								backgroundColor: "#EBF8F6",
							}, cursor: "pointer"}}
							overflow={"hidden"}
						>
							<MenuItem
								sx={{
									color: theme.palette.text.primary,
									":hover": { backgroundColor: "#EBF8F6" }
								}}
							>
								<Typography
									sx={{ color: theme.palette.text.primary }}
									variant="subtitle1"
								>
									<FormattedPhrase id="appMenu.myProfile" />
								</Typography>
							</MenuItem>
						</Link>

						<Link
							underline="none"
							onClick={() => setFAQModalOpen(true)}
							sx={{textDecoration: "none", flexDirection: "row", display: "flex", "&:hover": {
								backgroundColor: "#EBF8F6",
							}, cursor: "pointer"}}
							overflow={"hidden"}
						>
							<MenuItem
								sx={{
									color: theme.palette.text.primary,
									whiteSpace: "pre-wrap",
									":hover": { backgroundColor: "#EBF8F6" }
								}}
							>
								<Typography
									sx={{ color: theme.palette.text.primary }}
									variant="subtitle1"
								>
									<FormattedPhrase id="appMenu.faq" />
								</Typography>
							</MenuItem>
						</Link>
						{ 
							user && userService.isAssessmentManager() && 
							<Link
								underline="none"
								to="/assessment-management"
								onClick={() => redirectLogic()}
								component={RouterLink}
								sx={{textDecoration: "none", flexDirection: "row", display: "flex", "&:hover": {
									backgroundColor: "#EBF8F6",
								}, cursor: "pointer"}}
								overflow={"hidden"}
							>
								<MenuItem
									sx={{
										color: theme.palette.text.primary,
										":hover": { backgroundColor: "#EBF8F6" }
									}}
								>
									<Typography
										sx={{ color: theme.palette.text.primary }}
										variant="subtitle1"
									>
										Assessment Management
									</Typography>
								</MenuItem>
							</Link>
						}
						<Link
							underline="none"
							to="/logout"
							onClick={() => redirectLogic()}
							component={RouterLink}
							sx={{textDecoration: "none", flexDirection: "row", display: "flex", "&:hover": {
								backgroundColor: "#EBF8F6",
							}, cursor: "pointer"}}
							overflow={"hidden"}
						>
							<MenuItem
								sx={{
									color: theme.palette.text.primary,
									":hover": { backgroundColor: "#EBF8F6" }
								}}
							>
								<Typography
									sx={{ color: theme.palette.text.primary }}
									variant="subtitle1"
								>
									{labels.logOut}
								</Typography>
							</MenuItem>
						</Link>
					</Menu>
				</Toolbar>
				{location.pathname !== "/no-work" && (
					<Fragment>
						<Box
							className={
								location.pathname === "/"
									? "sub-header"
									: selectedLanguage?.direction === "rtl"
									? "sub-header-secondary-rtl"
									: "sub-header-secondary"
							}
							sx={{ backgroundColor: theme.palette.secondary.contrastText }}
						>
							{location.pathname !== "/" ? (
								<Grid py={mainTabGroup.length > 0 ? 2 : 0} display={"flex"} alignItems={"center"} justifyContent={window.innerWidth > 1024 ? "unset" : "center"} minHeight={65}>
									{window.innerWidth > 1024 && getBackButton(2)}
									{mainTabGroup.length > 0 ? <TabGroup /> : <></>}
									{location.pathname === "/raters" ? (
										<Typography
											sx={{ color: "black", minHeight: "70px", width: "100%", marginLeft: window.innerWidth > 1024 ? "-10%" : 0, pt: "20px" }}
											textAlign={"center"}
											variant="h4"
										>
											{raterPageTitle}
										</Typography>
									) : (
										<></>
									)}
								</Grid>
							) : (
								<Fragment>
									{profileResponse.isParticipant && profileResponse.isRater && (
										<Fragment>
											<Button
												variant="text"
												className={
													selectedSection === "Own"
														? "button-header selected-button"
														: "button-header"
												}
												sx={{
													margin: 4,
													height: "35px",
													"&:hover": {
														backgroundColor: theme.palette.primary.dark,
														borderRadius: "100px",
													},
												}}
												onClick={() => dispatch(setSelectedSection("Own"))}
											>
												<Typography
													variant="h5"
													sx={{
														color: theme.palette.text.primary,
														paddingX: 2,
													}}
												>
													<FormattedPhrase id="tabbedLanding.tab.yourSurveys" />
												</Typography>
											</Button>

											<Button
												variant="text"
												className={
													selectedSection !== "Own"
														? "button-header selected-button"
														: "button-header"
												}
												sx={{
													height: "35px",
													"&:hover": {
														backgroundColor: theme.palette.primary.dark,
														borderRadius: "100px",
													},
												}}
												onClick={() => dispatch(setSelectedSection("Others"))}
											>
												<Typography
													variant="h5"
													sx={{
														color: theme.palette.text.primary,
														paddingX: 2,
													}}
												>
													<FormattedPhrase id="tabbedLanding.tab.surveysForOthers" />
												</Typography>
											</Button>
										</Fragment>
									)}
								</Fragment>
							)}
						</Box>
						{tabs.length > 0 && location.pathname !== "/" && (
							<Box className="sub-heading">
								<TabbedNavigation />
							</Box>
						)}
					</Fragment>
				)}
			</AppBar>
		</Fragment>
	);
};

export default ResponsiveAppBar;
