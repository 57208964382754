import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Grid, Typography, Stack, Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import "../HomePage.scss";
import { setInstrumentId, setParticipantId } from "../../store/User/UserSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type ComponentProps = {
	warningText: string;
	alertText?: string;
	buttonText: string;
	surveyName: string;
	surveyId: number;
	participantId: number;
	complete?: boolean;
};

export const DisplayWarnings: React.FC<ComponentProps> = ({
	warningText,
	alertText,
	buttonText,
	surveyName,
	surveyId,
	participantId,
	complete
}) => {
	const dispatch = useDispatch();

	useEffect(() => {}, [warningText, alertText]);
	const theme = useTheme();

	const generateAccessibilityLabel = (surveyName: string, action: string, information: string) => {
		if (information) {
			return information + " " + surveyName + "\n" + action;
		}
		
		return action + " " + surveyName;
	}

	return (
		<Box
			className="survey-warnings"
			sx={{ backgroundColor: theme.palette.secondary.contrastText, borderRadius: "15px 15px 0 0" }}
		>
			<Grid container alignItems={"center"} display={"flex"}>
				<Grid item display={"flex"} flexDirection={"column"} xs={12} md={8}>
					<Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
						<Typography>{warningText}</Typography>
						{complete ?
							<CheckCircleIcon
								sx={{
									cursor: "default",
									fontWeight: 500,
									fontSize: "0.938rem",
									borderRadius: "100px",
									alignContent: "center",
									height: 15,
									width: 15,
									mt: 0.75,
									ml: 2,
									borderWidth: 1,
									color: "#4B7E37",
								}}
								className="btn btn-outline-primary"
							/> : <></>
						}
					</Grid>
					{ alertText && 
						<Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<WarningAmberIcon sx={{fontSize: 16, mr: 2}}/>
							<Typography>{alertText}</Typography>
						</Grid>
					}
				</Grid>
				
				<Grid item display={"flex"} flexDirection={"row-reverse"} xs={12} md={4}>
					<Link
						aria-label={generateAccessibilityLabel(surveyName,buttonText,warningText)}
						to={`/raters?id=${surveyId}&pid=${participantId}`}
						className="link-style"
						style={{ textDecoration: "none" }}
						onClick={() => {
							dispatch(setParticipantId(participantId));
							dispatch(setInstrumentId(surveyId));
						}}
					>
						<Button
							sx={{
								backgroundColor: theme.palette.secondary.contrastText,
								borderColor: theme.palette.primary.main,
								"&:disabled": {
									color: theme.palette.text.disabled,
									borderColor: theme.palette.text.disabled,
								},
								"&:hover": {
									backgroundColor: "#EAEFF4",
									color: theme.palette.primary.main,
								},
								color: theme.palette.primary.main,
								borderRadius: 100,
							}}
							variant="outlined"
						>
							{buttonText}
						</Button>
					</Link>
				</Grid>
			</Grid>
		</Box>
	);
};
