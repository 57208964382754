import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { Button, Grid, Typography, useTheme } from "@mui/material";

import {
	setAllowNext,
	setAllowPrevious,
} from "../../../store/Navigation/NavSlice";
import { RootState } from "../../../store";
import { SurveySectionProps } from "./SurveySectionComponent";

import "./SurveySection.scss";
import { Link } from "react-router-dom";
import { ResetSurveyStatus } from "../../../pages/home/ResetSurveyStatus";
import { FormattedPhrase } from "../../../context/FormattedPhrase";

export const SurveyCompleteComponent: React.FC<SurveySectionProps> = ({
	surveySection,
}) => {
	const theme = useTheme();
	const { showSectionSubheading } = useSelector(
		(state: RootState) => state.navigation
	);
	const { activeSurvey, localSurveyLanguage } = useSelector((state: RootState) => state.user);

	useEffect(() => {
		setAllowNext(false);
		setAllowPrevious(true);
	}, [surveySection]);

	return (
		<Fragment>
			<ResetSurveyStatus completed={true}/>
			<Grid item xs={12} sm={12} md={12} className="rounded-card">
				<Grid item md={12}>
					<h1>{activeSurvey.surveyDocument?.survey?.name}</h1>
				</Grid>
				<Grid item md={12}>
					<h3>{surveySection?.heading}</h3>
				</Grid>
				<Grid item md={12}>
					{showSectionSubheading && surveySection?.subheadings && (
						<div className="section-subheading">
							{surveySection?.subheadings?.map((item, index) => {
								return (
									<Typography
										gutterBottom
										variant="h6"
										component="div"
										className="p-3 text-left question-title"
										key={index}
									>
										{item}
									</Typography>
								);
							})}
						</div>
					)}
				</Grid>
				{surveySection?.instructions?.map((item, index) => {
					return (
						<Grid item xs={12} key={index}>
							<ReactMarkdown rehypePlugins={[rehypeRaw]}>{item}</ReactMarkdown>
						</Grid>
					);
				})}
					<Button
						sx={{
							backgroundColor: theme.palette.secondary.contrastText,
							borderColor: theme.palette.primary.dark,
							color: theme.palette.primary.dark,
							borderRadius: 100,
						}}
						variant="outlined"
					>
						<Link to="/" style={{textDecoration: "none"}}>
							<FormattedPhrase id="dashboard.footer.returnToAP" language={localSurveyLanguage} />
						</Link>
					</Button>
			</Grid>
		</Fragment>
	);
};
