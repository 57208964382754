import React, { Fragment } from "react";

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Modal,
	useTheme,
} from "@mui/material";
import components from "../../theme/components";

type ConfirmationModalParams = {
	isOpen: boolean;
	title: string;
	promptText?: string;
	promptBody?: JSX.Element;
	yesText: string;
	noText?: string;
	onYes?: () => void;
	onNo?: () => void;

	defaultButton?: string;
	dangerButton?: string;
};

export const ConfirmationModal: React.FC<ConfirmationModalParams> = ({
	isOpen,
	title,
	promptText,
	promptBody,
	yesText,
	noText,
	onYes,
	onNo,

	defaultButton,
	dangerButton
}) => {
	const theme = useTheme();

	const checkStyle = (btnText: string) => {
		let bgColor = theme.palette.primary.main;
		let btnTextColor = theme.palette.secondary.contrastText;
		let borderWidth = 1;
		let hover = {
			backgroundColor: theme.palette.primary.main,
			borderWidth: 1
		};

		if (defaultButton === btnText){
			bgColor = theme.palette.primary.main;
			btnTextColor = theme.palette.primary.contrastText;
			hover.borderWidth = 0;
		}
		else if (dangerButton === btnText) {
			bgColor = theme.palette.secondary.contrastText;
			btnTextColor = theme.palette.primary.main;
			borderWidth = 1;
			hover.borderWidth = 0;
		}

		return {
			backgroundColor: bgColor,
			color: btnTextColor,
			borderRadius: "100px",
			borderWidth: borderWidth,
			mr: 2,
			"&:hover": {
				backgroundColor: dangerButton === btnText ? "#EAEFF4" : "rgba(47, 95, 149, 0.9)",
			},
		}
	}

	return (
		<Modal open={isOpen}>
			<Box sx={[components.MuiModal,{padding: 2}]}>
			<DialogTitle fontSize={20}>{title}</DialogTitle>
			<Box sx={{borderBottomWidth: 0.5, borderBottomStyle: "solid", borderBottomColor: "rgba(0,0,0,0.25)", mx: 5}}/>
			<DialogContent>
				{promptText && <DialogContentText fontSize={14}>{promptText}</DialogContentText>}
				{promptBody && <Fragment>{promptBody}</Fragment>}
			</DialogContent>
			<DialogActions>
				{noText &&
				<Button 
					onClick={onNo}
					variant="outlined"
					sx={checkStyle(noText)}
				>{noText}</Button>
				}
				<Button
					onClick={onYes}
					sx={checkStyle(yesText)}
					variant="outlined"
				>
					{yesText}
				</Button>
			</DialogActions>
			</Box>
		</Modal>
	);
};
