import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom-v5-compat";

import { useAuth0 } from "@auth0/auth0-react";

import {
	fetchSurvey,
	setInstrumentId,
	setParticipantId,
	setSurveyReopened,
} from "../../store/User/UserSlice";
import { Survey } from "../../components/surveys/Survey";

import { RootState } from "../../store";
import { Layout } from "../../components/chrome/Layout";
import { Container } from "@mui/material";
import { NavigationModes } from "../../@types/surveys.d";
import {
	setCurrentNavigationMode,
	setReturnUrl,
	setWaitForSurveyUpdate,
} from "../../store/Navigation/NavSlice";

import "./Surveys.scss";

export const AboutMeSurvey: React.FC = () => {
	const dispatch = useDispatch();
	const { user } = useAuth0();

	const [search] = useSearchParams();

	const { activeAnswers } = useSelector((state: RootState) => state.user);

	const asyncLoadingTasks = async () => {
		let temp = search.get("retUrl");
		let retUrl = temp ?? ""; // Default is none.

		// On init page load, go get the user's About Me survey
		dispatch(
			fetchSurvey({
				user: user,
				instrumentId: 2946, // For About Me
			})
		);
		// redirect user to AP1
		sessionStorage.setItem("returnToAp1", retUrl ?? "");
		sessionStorage.setItem("redirectUser", "");
		dispatch(setInstrumentId(2946));
		dispatch(setParticipantId(0)); // Not used for About Me. Everyone only gets one instance.
		dispatch(setReturnUrl(retUrl));
		dispatch(setSurveyReopened(false));
		dispatch(setCurrentNavigationMode(NavigationModes.BySection));
		dispatch(setWaitForSurveyUpdate(false));
	};

	useEffect(() => {
		asyncLoadingTasks();
	}, []);

	return (
		<Layout>
			<Container id="about_me_survey">
				<Survey centerQuestions={false} />
			</Container>
		</Layout>
	);
};
