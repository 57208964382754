import { margin } from "@mui/system";
import variants from "./variants";

const components = {
	settingsCard: {
		borderRadius: "10px",
	},
	advertisementCard: {
	},
	MuiButtonBase: {
		defaultProps: {
			disableRipple: true,
		},
	},
	MuiListItemIcon: {
		styleOverrides: {
			root: {
				minWidth: 30,
				color: "#3b3b3b",
			},
		},
	},
	MuiLink: {
		defaultProps: {
			underline: "hover",
		},
	},
	MuiListItem: {
		styleOverrides: {
			root: {
				"&.Mui-selected": {
					fontWeight: "900",
				},
			},
		},
	},
	MuiCardHeader: {
		defaultProps: {
			titleTypographyProps: {
				variant: "h6",
			},
		},
		styleOverrides: {
			action: {
				marginTop: "-4px",
				marginRight: "-4px",
			},
		},
	},
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: "12px",
				boxShadow:
					"rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
				backgroundImage: "none",
			},
		},
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				backgroundImage: "none",
			},
		},
	},
	MuiPickersDay: {
		styleOverrides: {
			day: {
				fontWeight: "300",
			},
		},
	},
	MuiPickersYear: {
		styleOverrides: {
			root: {
				height: "64px",
			},
		},
	},
	MuiPickersCalendar: {
		styleOverrides: {
			transitionContainer: {
				marginTop: "6px",
			},
		},
	},
	MuiPickersCalendarHeader: {
		styleOverrides: {
			iconButton: {
				backgroundColor: "transparent",
				"& > *": {
					backgroundColor: "transparent",
				},
			},
			switchHeader: {
				marginTop: "2px",
				marginBottom: "4px",
			},
		},
	},
	MuiPickersClock: {
		styleOverrides: {
			container: {
				margin: `32px 0 4px`,
			},
		},
	},
	MuiPickersClockNumber: {
		styleOverrides: {
			clockNumber: {
				left: `calc(50% - 16px)`,
				width: "32px",
				height: "32px",
			},
		},
	},
	MuiPickerDTHeader: {
		styleOverrides: {
			dateHeader: {
				"& h4": {
					fontSize: "2.125rem",
					fontWeight: 400,
				},
			},
			timeHeader: {
				"& h3": {
					fontSize: "3rem",
					fontWeight: 400,
				},
			},
		},
	},
	MuiPickersTimePicker: {
		styleOverrides: {
			hourMinuteLabel: {
				"& h2": {
					fontSize: "3.75rem",
					fontWeight: 300,
				},
			},
		},
	},
	MuiPickersToolbar: {
		styleOverrides: {
			toolbar: {
				"& h4": {
					fontSize: "2.125rem",
					fontWeight: 400,
				},
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				borderRadius: "12px",
			},
		},
	},
	MuiStack: {
		styleOverrides: {
			root: {
				marginTop: "0.3rem",
			},
		},
	},
	MuiGrid: {
		styleOverrides: {
			root: {
				marginTop: "0rem",
			},
		},
	},
	MuiBox: {
		styleOverrides: {},
	},
	MuiSwitch: {
		styleOverrides: {
			switchBase: {
				// Controls default (unchecked) color for the thumb
				color: "#fff",
			},
			colorPrimary: {
				"&.Mui-checked": {
					// Controls checked color for the thumb
					color: "#ffff",
				},
			},
			track: {
				// Controls default (unchecked) color for the track
				opacity: 0.2,
				backgroundColor: "#C8C9CA",
				".Mui-checked.Mui-checked + &": {
					// Controls checked color for the track
					opacity: 0.7,
					backgroundColor: "#7B2682",
					borderColor: "#7B2682",
					border: 2,
				},
			},
		},
	},
	MuiTextField: {
		styleOverrides: {
			root: {
				margin: "1rem",
			},
		},
	},
	MuiModal: {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "40%",
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 50,
		p: 4,
		paddingLeft: 7,
	},
	MuiVideoModal: {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		bgcolor: "background.paper",
		borderRadius: 2,
		boxShadow: 50,
		p: 4,
		paddingLeft: 7,
	},
};

export default components;
