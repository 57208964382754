import { useSelector } from "react-redux";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import { Loading } from "../components";

import { RootState } from "../store";

import { Layout } from "../components/chrome/Layout";

import { FormattedPhrase } from "../context/FormattedPhrase";

import "./HomePage.scss";

export const NoWorkPage: React.FC = () => {
	const { loading } = useSelector((state: RootState) => state.user);

	return (
		<Layout>
			<Box id="survey_nowork">
				{loading && <Loading />}
				<Grid container columnSpacing={8} rowSpacing={5}>
					<Grid item xs={3} rowSpacing={5}></Grid>
					<Grid item xs={6} rowSpacing={5}>
						<Card
							sx={{
								px: "17px",
								py: "5px",
							}}
						>
							<CardContent>
								<Typography variant="h4" my={2}>
									<FormattedPhrase id="dialog.userNotFound.title" isHtml={true} />
								</Typography>
								<Typography variant="h5" my={2}>
									<FormattedPhrase id="noWorkLanding.text" isHtml={true} />
								</Typography>
								<Typography variant="h5" my={2}>
									<FormattedPhrase id="globalException.contactUs" isHtml={true} />
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={3} rowSpacing={5}></Grid>
				</Grid>
			</Box>
		</Layout>
	);
};
