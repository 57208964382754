import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import {
	fetchProgramStatuses,
	fetchSurveyUsage,
	setLoading,
	setProgramStatuses,
} from "../../store/User/UserSlice";

import { InstrumentStatus, ProgramStatus } from "../../@types/surveys.d";
import { RootState } from "../../store";

import { DisplayAboutMeStatus } from "../home/DisplayAboutMeStatus";
import { DisplayProgramInstrumentStatus } from "../home/DisplayProgramInstrumentStatus";

import { DisplayAdvertisement } from "../../components/ads/DisplayAdvertisement";
import { ResetSurveyStatus } from "./ResetSurveyStatus";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import SkeletonLoader from "../../components/shared/skeletonLoader";
import Snackbar from '@mui/material/Snackbar';
import { setShowToast } from "../../store/Navigation/NavSlice";

export const AssessmentsForMe: React.FC = () => {
	const dispatch = useDispatch();

	const { user } = useAuth0();

	const { programStatuses, surveyUsageData, loading } = useSelector((state: RootState) => state.user);
	const { returnUrl, showToast } = useSelector((state: RootState) => state.navigation);

	const [aboutMeStatus, setAboutMeStatus] = useState<InstrumentStatus | null>(
		null
	);
	const [onlyAboutMe, setOnlyAboutMe] = useState<boolean>();
	const [programs, setPrograms] = useState<Array<ProgramStatus> | null>(null);

	useEffect(() => {
		if (returnUrl === "") {
			sessionStorage.setItem("redirectUser", "");
			sessionStorage.setItem("redirectOnLogOut", "");
			sessionStorage.setItem("returnToAp1", "");
		}
		if (programStatuses && programStatuses.length > 0) {
			let statuses: Array<ProgramStatus> = [];

			// set About Me status based on all the surveys assigned in all the sessions
			if (programStatuses.length > 1) {
				programStatuses.map((x) => {
					x.instrumentStatuses.filter((y) => {
						if (y.instrumentId === 2946) {
							setAboutMeStatus(y);
						} else if (y.instrumentId !== 2946) {
							setOnlyAboutMe(false);
						}
					})
				});
			}
			else {
				programStatuses.map((x) => {
					let aboutMe = x.instrumentStatuses.find(
						(s) => s.instrumentId === 2946
					);
					if (aboutMe) {
						setAboutMeStatus(aboutMe);
					}
					let others = x.instrumentStatuses.find(
						(s) => s.instrumentId !== 2946
					);
					if (others) {
						setOnlyAboutMe(false);
					} else {
						setOnlyAboutMe(true);
					}
				})
			}
			programStatuses.forEach((element) => {
				let newInstrumentStatuses: Array<InstrumentStatus> = [];
				element.instrumentStatuses.forEach((a) => {
					if (surveyUsageData) {
						surveyUsageData.map((x) => {
						if (a.instrumentId === x.instrumentKey) {
							newInstrumentStatuses.push({
								...a,
								isSurveyAvailable: x.active,
								surveyPlatform: x.surveyPlatform
							});
						}
						})
					}
					if (a.documentInfo?.documentId) {
						newInstrumentStatuses.push(a);
					}
					if (a.assetInfo?.assetInfoId && !a.isAssessment) {
						newInstrumentStatuses.push(a);
					}
				});
				statuses.push({
					...element,
					instrumentStatuses: newInstrumentStatuses
				})
			});
			setPrograms(statuses);
		}
	}, [programStatuses, surveyUsageData]);

	const asyncLoadingTasks = async () => {
		if (user) {
			dispatch(setLoading(true));
			try {
				let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];
				dispatch(fetchProgramStatuses(acctId));
				dispatch(fetchSurveyUsage());
			} finally {
				dispatch(setLoading(false));
			}
		}
	};

	const handleClose = (
		event: React.SyntheticEvent | Event,
	) => {
		dispatch(setShowToast(false));
	};

	useEffect(() => {
		sessionStorage.setItem("redirectUser", "");
		asyncLoadingTasks();
		window.scrollTo(0, 0);
	}, [user]);

	return (
		<Grid container columnSpacing={8} rowSpacing={5}>
			{!loading &&
				<>
				{
					aboutMeStatus ? (
						<Grid item xs={12} md={4}>
							<DisplayAboutMeStatus instrumentStatus={aboutMeStatus} />
							<DisplayAdvertisement />
						</Grid>
					)
					:
					<Grid item xs={4} rowSpacing={1} mb={5}>
						<DisplayAdvertisement />
					</Grid>
				}
				{onlyAboutMe ? (
					<Grid item xs={12} md={4}>
						<Typography variant="h5" mt={20}>
							<FormattedPhrase id="noWorkLanding.text" isHtml={true} />
						</Typography>
						<Typography variant="h5" mt={10}>
							<FormattedPhrase id="globalException.contactUs" isHtml={true} />
						</Typography>
					</Grid>
				) : programs && programs?.length > 0 ? (
					<Grid item xs={12} md={8} rowSpacing={5}>
						<DisplayProgramInstrumentStatus programStatuses={programs} />
					</Grid>
				) : (
					<Grid item xs={8} rowSpacing={1} mb={5}>
						<SkeletonLoader cardType="survey" />
						<SkeletonLoader cardType="survey" />
					</Grid>
				)}
				</>
			}
			<ResetSurveyStatus />
			<Snackbar
				open={showToast}
				onClose={handleClose}
				message={<FormattedPhrase id="download.message" isHtml={true} />}
				autoHideDuration={6000}
			/>
		</Grid>
	);
};
