import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";

import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "@mui/material";
import { useEnv } from "../../context/env.context";

import {
	fetchSurvey,
	setCurrentSection,
	setInstrumentId,
	setParticipantId,
	setSelectedSurveyType,
	setSurveyReopened,
} from "../../store/User/UserSlice";
import {
	setContinueAtNextUnanswered,
	setCurrentGroupIndex,
	setCurrentNavigationMode,
	setCurrentQuestionIndex,
	setMaximumPrevious,
	setQuestionNumber,
	setReturnUrl,
	setScaleDisplayMode,
	setSectionId,
	setShouldRenderSeqNum,
	setShowSectionSubheading,
	setWaitForSurveyUpdate,
} from "../../store/Navigation/NavSlice";
import { Survey } from "../../components/surveys/Survey";

import { Layout } from "../../components/chrome/Layout";

import {
	NavigationModes,
	ScaleDisplayModes,
	SurveySection,
} from "../../@types/surveys.d";
import { RootState } from "../../store";

import "./Surveys.scss";
import { useHistory } from "react-router-dom";
import usePageTitle from "../../hooks/pageTitle";

export const AssessmentSurvey: React.FC = () => {
	const dispatch = useDispatch();
	const { user } = useAuth0();
	const navigate = useNavigate();
	const [search] = useSearchParams();

	const { selectedLanguage, selectedSurveyLanguage, activeSurvey } = useSelector((state: RootState) => state.user);
	const { instrumentId, participantId } = useSelector(
		(state: RootState) => state.user
	);

	const getTitle = () => {
		if (activeSurvey?.surveyDocument?.survey?.name) {
			if (activeSurvey?.surveyDocument?.survey?.surveyTypeId === 0) {
				return activeSurvey?.surveyDocument?.survey?.name
			}

			else {
				return activeSurvey?.surveyDocument?.survey?.name + " (Rater)"
			}
		}

		return "Assessments | Center for Creative Leadership";
	}

	usePageTitle(getTitle());

	useEffect(() => {}, [selectedLanguage, selectedSurveyLanguage]);

	const setSurveyOptions = async (insId: number) => {
		switch (insId) {
			case 2946: // About Me 2.0
				dispatch(setCurrentNavigationMode(NavigationModes.BySection));
				dispatch(setWaitForSurveyUpdate(false));
				break;

			case 2947: // Workplace Big 5 - 5.0 settings
				// setting this to true causes the question to repeat sometimes
				dispatch(setContinueAtNextUnanswered(false));
				dispatch(setShowSectionSubheading(false));
				dispatch(setShouldRenderSeqNum(true));

				dispatch(setCurrentNavigationMode(NavigationModes.ByQuestion));
				dispatch(setScaleDisplayMode(ScaleDisplayModes.AlignHorizontally));
				dispatch(setMaximumPrevious(1));
				break;

			case 2948: // REAL Companion settings
				dispatch(setCurrentNavigationMode(NavigationModes.BySection));
				dispatch(setScaleDisplayMode(ScaleDisplayModes.AlignVertically));
				dispatch(setMaximumPrevious(99));
				break;

			default:
				dispatch(setCurrentNavigationMode(NavigationModes.ByQuestionGroup));
				dispatch(setScaleDisplayMode(ScaleDisplayModes.AlignVertically));
				dispatch(setMaximumPrevious(99));
				break;
		}
	};

	const loadFromQuery = async (
		insId: number,
		pid: number,
		retUrl: string | null,
		surveyType: number
	) => {
		if (retUrl) {
			sessionStorage.setItem("returnToAp1", retUrl);
			sessionStorage.setItem("redirectUser", "");
			sessionStorage.setItem("redirectUser", "");
		}
		else {
			dispatch(setReturnUrl(""));
			sessionStorage.setItem("returnToAp1", "");
			sessionStorage.setItem("redirectUser", "");
		}
		switch (insId) {
			case 2947:
				// Send to the wb5p component for setup
				if (retUrl) {
					navigate(`/wb5p?id=${insId}&pid=${pid}&retUrl=${retUrl}`);
				} else {
					navigate(`/wb5p?id=${insId}&pid=${pid}`);
				}
				break;

			default:
				let requestData = {
					user: user,
					instrumentId: insId,
					participantId: pid,
					languageId: insId === 2947 ? selectedSurveyLanguage?.languageId : selectedLanguage?.languageId,
					surveyTypeId: surveyType
				};
				dispatch(fetchSurvey(requestData));

				dispatch(setInstrumentId(insId));
				dispatch(setParticipantId(pid));
				dispatch(setReturnUrl(retUrl ?? ""));
				dispatch(setSurveyReopened(false));
				dispatch(setSelectedSurveyType(surveyType));

				setSurveyOptions(insId);

				// Redirect to this page with everything set.
				navigate("/survey");
		}
	};

	const loadFromState = async () => {
		if (!instrumentId || !participantId) {
			navigate("/");
		}

		switch (instrumentId) {
			case 2947:
				// Send to the wb5p component for setup
				navigate(`/wb5p?id=${instrumentId}&pid=${participantId}`);
				break;

			default:
				dispatch(setCurrentGroupIndex(-1));
				dispatch(setCurrentQuestionIndex(-1));
				dispatch(setCurrentSection({} as SurveySection));
				dispatch(setSectionId(""));
				dispatch(setQuestionNumber(0));

				setSurveyOptions(instrumentId);
				break;
		}
	};

	useEffect(() => {
		let id = search.get("id");
		let pid = search.get("pid");
		let retUrl = search.get("retUrl");
		let surveyType = search.get("surveyType");
		sessionStorage.setItem("redirectUser","");
		if (id && pid && surveyType) {
			// start based on the query query params
			loadFromQuery(parseInt(id), parseInt(pid), retUrl, parseInt(surveyType));
		} else {
			// Load based on state settings.
			loadFromState();
		}
	}, []);

	return (
		<Layout>
			<Container id="assessment_survey">
				<Survey centerQuestions={false} />
			</Container>
		</Layout>
	);
};
