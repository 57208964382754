import React from "react";
import { Card, Grid, Stack } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

export interface LoaderProps {
	cardType: string;
}

const selectCard = (type: string) => {
	switch (type) {
		case "survey":
			return (
				<Card sx={{
					px: "17px",
					py: "2px",
					mb: "32px",
					height: 300
				}}>
					<Stack spacing={1}>
						<Skeleton animation="wave" height={20} width={100} />
						<Skeleton animation="wave" height={20} width={200} />
						<Skeleton animation="wave" height={250} width={"100%"} />
					</Stack>
				</Card>
			);
		case "aboutMe":
			return (
				<Card sx={{
					px: "17px",
					height: 450
				}}>
					<Stack spacing={1}>
						<Skeleton animation="wave" height={320} />
						<Skeleton animation="wave" height={20} width={100} />
						<Skeleton animation="wave" height={20} width={200} />
						<Stack direction={"row"} spacing={2}>
							<Skeleton animation="wave" height={50} width={80} />
							<Skeleton animation="wave" height={50} width={80} />
						</Stack>
					</Stack>
				</Card>
			);
	}
}

export const SkeletonLoader: React.FC<LoaderProps> = (props) => {
	return (
		<Grid spacing={1}>
			{selectCard(props.cardType)}
		</Grid>
	);
};

export default SkeletonLoader;
